import type { Splide, Components, Options } from '@splidejs/splide';

/**
 * Extension to add a custom slidebar to the Splide slider for visualizing and controlling the
 * slide position.
 *
 * If you want to render a sidebar in a specific place in the HTML, add the sidebar's markup
 * directly to your template. If no markup is provided, the sidebar will be automatically created
 * and inserted right before the closing tag of the Splide root element.
 *
 * @param splide The main Splide instance
 * @param components Splide components
 * @param options Splide options object, containing customization options
 *
 * @see https://splidejs.com/components/
 * @see https://splidejs.com/guides/options/
 * @see https://splidejs.com/guides/extension/
 */
export function splideSlidebarExtension(splide: Splide, components: Components, options: Options) {
  /**
   * Root slidebar element
   */
  let slidebar: HTMLElement;

  /**
   * Indicates whether the sidebar markup is already present in the HTML or not.
   */
  let hasSlidebarMarkup = false;

  /**
   * Slide bar handle element
   */
  let handle: HTMLElement;

  /**
   * Slidebar current position
   */
  let position = 0;

  /**
   * Maximum allowable position value for the slidebar handle
   */
  let maxPosition: number;

  /**
   * Maximum allowable position value for the Splide's track. Store the value to not calculate all
   * the time when the slider is dragged.
   */
  let maxTrackPosition: number;

  /**
   * Previous x-coordinate of the touch, needed to calculate the touch movement distance.
   */
  let previousTouchX = 0;

  /**
   * Mounts the extension, setting up the slidebar and initializing event listeners. Splide call
   * this method automatically when the slider is ready.
   *
   * @see https://splidejs.com/guides/extension/
   */
  function mount() {
    slidebar = components.Elements.root.querySelector('.splide__slider');
    handle = components.Elements.root.querySelector('.splide__slider-handle');

    if (slidebar && handle) {
      hasSlidebarMarkup = true;
    } else {
      renderSlidebar();
    }

    splide.on('ready', onSplideReady);
    splide.on('updated', onSplideOptionsUpdated);
    splide.on('overflow', onSplideOverflowed);
  }

  /**
   * Enables the slidebar functionality, adding the necessary event listeners.
   */
  function enable() {
    if (!hasSlidebarMarkup) {
      components.Elements.root.appendChild(slidebar);
    }

    slidebar.classList.remove('hidden');

    // Without this delay, the first page of the carousel is not clickable. There’s an issue with
    // calling the components.Controller.go() method immediately when the extension is mounted.
    setTimeout(onSplideResized);

    if (!splide.is('fade')) {
      splide.on('dragging.slidebar', onSplideDragging);
    }

    splide.on('move.slidebar', onSplideMoveStarted);
    splide.on('resized.slidebar', onSplideResized);
    handle.addEventListener('mousedown', onMousedown);
    handle.addEventListener('touchstart', onTouchstart, { passive: true });
    handle.addEventListener('transitionend', onTransitionend);
    slidebar.addEventListener('click', onSlidebarClicked);
  }

  /**
   * Disables the slidebar functionality, removing the event listeners and slidebar element.
   */
  function disable() {
    slidebar.classList.add('hidden');

    splide.off('move.slidebar');
    splide.off('resized.slidebar');
    splide.off('dragging.slidebar');
    handle.removeEventListener('mousedown', onMousedown);
    handle.removeEventListener('touchstart', onTouchstart);
    handle.removeEventListener('transitionend', onTransitionend);
    slidebar.removeEventListener('click', onSlidebarClicked);
  }

  /**
   * Creates elements, calculates, and sets the handle width based on the number of slides, but does
   * not append the element to the DOM.
   */
  function renderSlidebar() {
    slidebar = document.createElement('div');
    slidebar.classList.add('splide__slider');

    handle = document.createElement('div');
    handle.classList.add('splide__slider-handle');

    handle.style.width = calculateHandleWidth();

    slidebar.appendChild(handle);
  }

  /**
   * Calculates the width of the handle.
   *
   * The function works depending upon the 'mode' the Splide is in. If it's in 'fade' mode, the
   * width is calculated based on the proportion of 'perPage' option to the total number of slides,
   * scaled to fit a percentage scale (0-100).
   *
   * For other modes, the handle width is determined by the ratio of list size
   * to slider size, also expressed as a percentage.
   *
   * @returns The calculated width of the handle.
   */
  function calculateHandleWidth() {
    let handleWidth;
    if (splide.is('fade')) {
      handleWidth = (options.perPage / components.Slides.getLength()) * 100;
    } else {
      handleWidth = (components.Layout.listSize() / components.Layout.sliderSize()) * 100;
    }

    return `${handleWidth}%`;
  }

  /**
   * Calculates maximum values for the slidebar and track, ensuring they stay in sync.
   */
  function recalculateSlidebarAndTrackPosition() {
    maxPosition = slidebar.offsetWidth - handle.offsetWidth;
    maxTrackPosition = components.Layout.sliderSize() - components.Layout.listSize();

    components.Controller.go(splide.index, true);
  }

  /**
   * Processes and restricts handle movement within the slidebar bounds.
   *
   * @param toPosition Desired new position of the handle.
   */
  function processHandleMove(toPosition: number) {
    const newPosition = getHandlePositionWithinBounds(toPosition);

    // avoid position update if handle position hasn't changed.
    if (position === newPosition) {
      return;
    }

    position = getHandlePositionWithinBounds(toPosition);

    if (!splide.is('fade')) {
      const newSplidePosition = getCalculatedTrackPosition(position);
      components.Move.translate(newSplidePosition);
    }

    // it ensures the DOM updates are synchronized with the browser's refresh rate, avoiding
    // unnecessary reflows and keeping animations smooth
    requestAnimationFrame(() => {
      handle.style.transform = `translateX(${position}px)`;
    });
  }

  /**
   * Adjusts and returns the intended position of the handle within the boundaries of a slider.
   * Checks whether the new position is within the range [0, maxPosition]. If the intended position
   * is less than 0, it will be set to 0. If it's larger than maxPosition, it will be set
   * to maxPosition. If it's the same as the current position, it will return this value without
   * changes.
   *
   * @param toPosition - The intended new position.
   * @returns newPosition - The adjusted position within the range [0, maxPosition].
   */
  function getHandlePositionWithinBounds(toPosition: number) {
    let newPosition = toPosition;
    if (newPosition <= 0) {
      newPosition = 0;
    } else if (newPosition >= maxPosition) {
      newPosition = maxPosition;
    }

    return newPosition;
  }

  /**
   * Aligns the track position based on the current position of the handle.
   */
  function alignTrackPosition() {
    let index: number;

    if (splide.is('fade')) {
      index = Math.round(position / (maxPosition / components.Slides.getLength()));
    } else {
      const currentSplidePosition = components.Move.getPosition();
      index = components.Move.toIndex(currentSplidePosition);
    }

    components.Controller.go(index, true);
  }

  function onSplideOptionsUpdated(_options: Options) {
    toggleSlidebar();
  }

  function onSplideReady() {
    toggleSlidebar();
  }

  function toggleSlidebar() {
    // We need to both check isEnough() and isOverflow(). Depending on the Splide options or due
    // to some Splide bugs, solely relying on one of these methods has led to issues.
    if (options.slidebar && components.Slides.isEnough() && components.Layout.isOverflow()) {
      enable();
    } else {
      disable();
    }
  }

  function onMousedown() {
    addEventListener('mousemove', onMousemove);
    addEventListener('mouseup', onMouseup);
  }

  function onMouseup() {
    // TODO: "mouseup" event is fired on touch devices right after "touchend". Need to investigate,
    // if we can replace mouse and touch events with "pointerdown" and "pointerup" events.

    alignTrackPosition();

    removeEventListener('mousemove', onMousemove);
    removeEventListener('mouseup', onMouseup);
  }

  function onMousemove(event: MouseEvent) {
    const newPosition = position + event.movementX;
    processHandleMove(newPosition);
  }

  function onTouchstart(event: TouchEvent) {
    const touch = event.touches[0];
    previousTouchX = touch.clientX;

    addEventListener('touchmove', onTouchmove);
    addEventListener('touchend', onTouchend);
  }

  function onTouchend(_event: TouchEvent) {
    previousTouchX = 0;

    alignTrackPosition();

    removeEventListener('touchmove', onTouchmove);
    removeEventListener('touchend', onTouchend);
  }

  function onTouchmove(event: TouchEvent) {
    const touch = event.touches[0];
    const movementX = touch.clientX - previousTouchX;
    const newPosition = position + movementX;
    previousTouchX = touch.clientX;

    processHandleMove(newPosition);
  }

  /**
   * Resets handle transition style once transition ends
   */
  function onTransitionend() {
    handle.style.transition = '';
  }

  function onSlidebarClicked(event: MouseEvent) {
    if (event.target !== event.currentTarget) {
      return;
    }

    const newSlidebarPosition = event.offsetX - handle.offsetWidth / 2;
    let index: number;

    if (splide.is('fade')) {
      index = Math.round(newSlidebarPosition / (maxPosition / components.Slides.getLength()));
    } else {
      const newTrackPosition = getCalculatedTrackPosition(newSlidebarPosition);
      index = components.Move.toIndex(newTrackPosition);
    }

    components.Controller.go(index, true);
  }

  function onSplideResized() {
    handle.style.width = calculateHandleWidth();

    recalculateSlidebarAndTrackPosition();
  }

  function onSplideOverflowed(isOverflow: boolean) {
    if (isOverflow) {
      enable();
    } else {
      disable();
    }
  }

  /**
   * Sets the handle position based on the active slide's position.
   *
   * @param index Active Slide index.
   */
  function onSplideMoveStarted(index: number) {
    let newSlidebarPosition;
    if (splide.is('fade')) {
      newSlidebarPosition = (options.perPage / (components.Slides.getLength() - 1)) * maxPosition *
      index;
    } else {
      const newTrackPosition = components.Move.toPosition(index);
      newSlidebarPosition = getCalculatedSlidebarPosition(newTrackPosition);
    }

    position = getHandlePositionWithinBounds(newSlidebarPosition);
    handle.style.transform = `translateX(${position}px)`;
    handle.style.transition = 'transform 400ms cubic-bezier(0.25, 1, 0.5, 1)';
  }

  function onSplideDragging() {
    const newPosition = getCalculatedSlidebarPosition();
    position = getHandlePositionWithinBounds(newPosition);
    handle.style.transform = `translateX(${position}px)`;
  }

  /**
   * Calculates the handle's position based on the current track position.
   *
   * @param trackPosition Optional track position for calculation. If not provided the value from
   *   the Move component is taken. We don't need to pass a new track position if this function used
   *   to get the slidebar position when the slider is dragged, but it's necessary if used when
   *   the slider is moved to a certain slide because the position at the end of moving should be
   *   taken instead of the current Splide's track position.
   */
  function getCalculatedSlidebarPosition(trackPosition?: number) {
    const currentTrackPosition = -1 * (trackPosition ?? components.Move.getPosition());
    const ratio = currentTrackPosition / maxTrackPosition;
    const newSlideBarPosition = ratio * maxPosition;
    return newSlideBarPosition;
  }

  /**
   * Calculates the track's position based on the handle's position.
   *
   * @param slidebarPosition Position of the slidebar handle.
   */
  function getCalculatedTrackPosition(slidebarPosition: number) {
    const ratio = slidebarPosition / maxPosition;
    const newTrackPosition = -1 * ratio * maxTrackPosition;
    return newTrackPosition;
  }

  return {
    mount
  };
}
