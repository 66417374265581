import '../../lib/webpack-path.js';
import type { Options, SlideComponent, Splide } from '@splidejs/splide';
import { retry } from '../../lib/promise-retry.js';
import { splideSlidebarExtension } from '../../lib/splide-slidebar-extension.js';

function initCarousel(splideClass: typeof Splide, carousel: HTMLElement, index: number) {
  const options: Options = {
    type: 'slide',
    role: null,
    rewind: false,
    arrows: true,
    pagination: false,
    perPage: 1,
    perMove: 1,
    gap: 0,
    padding: 0,
    keyboard: true,
    mediaQuery: 'min',
    slidebar: true,
    breakpoints: {
      1024: {
        perPage: 4,
        perMove: 4,
        gap: 16
      },
      768: {
        perPage: 3,
        perMove: 3,
        gap: 16,
        padding: 0
      },
      425: {
        perPage: 2,
        perMove: 2,
        gap: 8,
        padding: { right: '15%' }
      },
      320: {
        perPage: 1,
        perMove: 1,
        gap: 8,
        padding: { right: '30%' }
      }
    }
  };

  const splide = new splideClass(carousel, options);

  splide.on('mounted', () => {
    const padding = splide.options.padding;
    const hasRightPadding = padding && typeof padding === 'object' && 'right' in padding &&
      padding.right;
    // if there's a padding right, the next slide is half-visible, and we should make it accessible
    const perPage = hasRightPadding ? splide.options.perPage + 1 : splide.options.perPage;
    splide.Components.Slides.forEach((sc, index) => {
      if (index >= perPage) {
        sc.slide.setAttribute('inert', '');
      }
    });
  });

  splide.on('moved', (newIndex: number, prevIndex: number) => {
    const padding = splide.options.padding;
    const hasRightPadding = padding && typeof padding === 'object' && 'right' in padding &&
      padding.right;
    // if there's a padding right, the next slide is half-visible, and we should make it accessible
    const perPage = hasRightPadding ? splide.options.perPage + 1 : splide.options.perPage;

    // make all slides that were visible including the half-visible previously inaccessible
    for (let i = 0; i < perPage; i++) {
      const slideComponent = splide.Components.Slides.getAt(prevIndex + i);
      if (slideComponent) {
        slideComponent.slide.setAttribute('inert', '');
        slideComponent.slide.setAttribute('aria-hidden', 'true');
      }
    }

    const unhide = (component: SlideComponent) => {
      // Splide counts a half-visible slide as a hidden slide and sets aria-hidden=true. This
      // callback fired before the Splide set aria-hidden, so we need this delay to set our
      // value

      // We must use optional chaining here because this is called via setTimeout. In between the
      // scheduling of the callback and the execution of the callback, the dom may change. While
      // unlikely, we have actually observed component arrive undefined.

      component?.slide?.removeAttribute('aria-hidden');
    };

    // make all slides that are visible including the half-visible slide now accessible
    for (let i = 0; i < perPage; i++) {
      const component = splide.Components.Slides.getAt(newIndex + i);
      if (component) {
        component.slide.removeAttribute('inert');
        setTimeout(unhide, 0, component);
      }
    }
  });

  if (index < 1) {
    mountSplide(splide);
  } else {
    // Delay mounting for carousels outside of the viewport
    setTimeout(mountSplide, 500 + (50 * index), splide);
  }

  splide.on('moved', onSplideMoved);
}

function mountSplide(splide: Splide) {
  // This function is sometimes called via setTimeout. We sometimes see splide being undefined. This
  // might be out of date bots that have incorrect implementations of setTimeout that do not support
  // passing parameters to the callback. Rather than just get spammed with errors from the bots, we
  // just do nothing. The site will not appear correctly but that's fine.
  //
  // This frequently happens in the Yeti browser, which seems to be an in app browser on Android.

  if (!splide) {
    console.log('splide undefined in mountSplide callback');
    return;
  }

  splide.mount({
    splideSlidebarExtension
  });
}

/**
 * Emit an event when carousel has been scrolled. It's fired even if there was just a small
 * movement that doesn't lead to changing the slide. The event is fired when "move" is finished
 * after transition animation ended.
 */
function onSplideMoved(_index: number, _prevIndex: number, _destIndex: number) {
  const event = new Event('product-carousel-scrolled');
  dispatchEvent(event);
}

async function init() {
  const carousels = document.querySelectorAll<HTMLElement>('.splide-product-card-carousel');
  if (carousels.length === 0) {
    return;
  }

  const { Splide: splide } = await retry(import('@splidejs/splide'));
  for (let i = 0; i < carousels.length; i++) {
    initCarousel(splide, carousels[i], i);
  }
}

/**
 * Inits product carousel on homepage, collections product list, recommendation pdp
 */
function onDOMContentLoaded(_event: Event) {
  init().catch(console.warn);
}

/**
 * This event is fired when a Splide carousel is "moved". It's fired even if there was just a small
 * movement that doesn't lead to changing the slide. The event is fired when "move" is finished
 * after transition animation ended.
 */
type ProductCarouselScrolledEvent = Event;

declare global {
  interface WindowEventMap {
    'product-carousel-scrolled': ProductCarouselScrolledEvent;
  }
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}
